.pdf-container {
    height: 100vh; /* Utilise toute la hauteur de la fenêtre */
    display: flex;
    justify-content: center;
    align-items: center;
}

.rpv-core__viewer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.formateur-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    background-color: #f5f5f5;
    padding: 20px;
    font-family: Arial, sans-serif;
}

.formateur-main-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.formateur-main-button {
    background-color: #007bff;
    color: white;
    padding: 15px 30px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 18px;
    margin: 0 10px;
}

.formateur-main-button:hover {
    background-color: #0056b3;
}

.formateur-menu-container {
    display: flex;
    flex-direction: column;
    width: 80%;
    max-width: 1200px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

.formateur-arborescence-menu, .formateur-fiche-menu {
    width: 100%;
}

.formateur-menu-title {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
}

.formateur-input-text, .formateur-select, .formateur-input-file {
    width: 100%;
    padding: 12px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 6px;
    font-size: 16px;
}

.formateur-button {
    background-color: #007bff;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 16px;
    margin-bottom: 20px;
    text-align: center;
}

.formateur-button:hover {
    background-color: #0056b3;
}

.formateur-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.formateur-list-item {
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.formateur-questions-container {
    margin-top: 20px;
}

.formateur-questions-list {
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #ccc;
    border-radius: 6px;
    padding: 15px;
}

.formateur-questions-list-item {
    padding: 8px;
    border-bottom: 1px solid #ddd;
}

.formateur-questions-list-item:last-child {
    border-bottom: none;
}

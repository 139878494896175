@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@400;500&family=Poppins:wght@400;600&display=swap');

.quiz-container {
    background: #000;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    padding: 20px;
    box-sizing: border-box;
}

.quiz-header {
    font-family: "Poppins", sans-serif;
    font-size: 2.5em;
    color: #fff;
    text-align: center;
    margin-bottom: 20px;
}

.quiz-question {
    font-family: "Poppins", sans-serif;
    font-size: 1.3em;
    color: #ffffff;
    text-align: center;
    margin-bottom: 20px;
    max-width: 80%;
}

.quiz-input {
    font-family: "DM Sans", sans-serif;
    font-size: 1em;
    padding: 10px;
    width: 100%;
    max-width: 300px;
    margin-bottom: 20px;
    border: 1px solid #ffffff;
    border-radius: 3px;
    background-color: transparent;
    color: #ffffff;
    text-align: center;
}

.quiz-button {
    font-family: "DM Sans", sans-serif;
    font-size: 1em;
    padding: 8px 16px;
    margin: 10px;
    border: 1px solid #ffffff;
    border-radius: 3px;
    background-color: transparent;
    color: #ffffff;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.quiz-button:hover {
    background-color: #ffffff;
    color: #000;
}

.quiz-feedback {
    font-family: "Poppins", sans-serif;
    font-size: 1.1em;
    color: #ffffff;
    text-align: center;
    margin-top: 20px;
}

.quiz-options {
    list-style-type: none;
    padding: 0;
}

.quiz-options li {
    margin: 10px 0;
}

.quiz-options button {
    width: 100%;
    max-width: 250px;
}

@media screen and (max-width: 767px) {
    .quiz-header {
        font-size: 2em;
    }

    .quiz-question {
        font-size: 1.1em;
    }

    .quiz-input, .quiz-button {
        font-size: 0.9em;
    }
}

.menu-container {
  display: flex;
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.menu {
  background-color: #333;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.3);
}

/* Styles pour les icônes */
.menu-icon {
  color: #fff;
  cursor: pointer;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 10px 20px;
  font-size: 24px;
}

.menu-icon i {
  font-size: 24px;
  transition: color 0.3s;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-icon span {
  margin-left: 10px;
  font-size: 16px;
  display: none;
}

/* Menu en bas à gauche pour les téléphones */
@media screen and (max-width: 767px) {
  .menu {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      flex-direction: row;
      justify-content: space-around;
  }

  .menu-icon {
      justify-content: center;
      padding: 10px;
      width: auto;
  }

  .menu-icon span {
      display: none;
  }

  .logout-icon {
      display: none;
  }

  .formateur-icon {
      display: none;
  }

  .admin-icon {
      display: none;
  }

  .content {
      margin-top: 0;
      width: 100vw;
      height: calc(100vh - 60px); /* Ajuste la hauteur pour exclure la barre de menu */
      overflow-y: auto;
  }
}

/* Menu à gauche pour les ordinateurs */
@media screen and (min-width: 768px) {
  .menu {
      position: fixed;
      left: 0;
      top: 0;
      bottom: 0;
      width: 200px;
      flex-direction: column;
      justify-content: flex-start;
      padding-top: 20px;
  }

  .menu-icon {
      margin: 20px 0;
      justify-content: flex-start;
      padding-left: 20%;
  }

  .menu-icon span {
      display: inline;
  }

  .logout-icon {
      display: flex;
      margin-top: auto; /* Place le bouton en bas du menu */
      padding-left: 20%;
  }

  .content {
      margin-left: 200px; /* Espace pour la barre de menu */
      width: calc(100vw - 200px);
      height: 100%;
      overflow-y: auto;
  }
}

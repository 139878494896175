.fiche-container {
    display: flex;
    height: 100vh;
}

.fiche-menu {
    background-color: #f4f4f4;
    padding: 10px;
    width: 250px;
    overflow-y: auto;
    border-right: 1px solid #ddd;
    display: flex;
    flex-direction: column;
}

.fiche-button {
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 10px;
    margin: 5px 0;
    text-align: left;
    width: 100%;
    cursor: pointer;
}

.fiche-content {
    flex-grow: 1;
    padding: 10px;
    overflow-y: auto;
}

.fiche-item {
    margin-bottom: 10px;
}

.hide-menu .fiche-menu {
    display: none;
}

.hide-menu .fiche-content {
    width: 100%;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
    .fiche-container {
        flex-direction: column;
    }

    .fiche-menu {
        width: 100%;
        border-right: none;
        border-bottom: 1px solid #ddd;
    }

    .fiche-content {
        flex-grow: 1;
        padding: 0;
        overflow-y: auto;
    }

    .hide-menu .fiche-content {
        width: 100%;
        padding: 10px;
    }
}

.spinner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f9f9f9;
    width: 100%;
  }
  
  .loading-spinner {
    border: 8px solid rgba(0, 0, 0, 0.1);
    border-top: 8px solid #aa0000;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 1s linear infinite;
    margin-bottom: 20px; /* Ajoute de l'espace entre le spinner et le texte */
  }
  
  .loading-text {
    font-family: Arial, sans-serif;
    font-size: 18px;
    color: #333;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    animation: fadein 1.5s ease-in-out infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  @keyframes fadein {
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
  }
  
.profile-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .profile-info {
    margin-bottom: 20px;
  }
  
  .profile-field {
    margin-bottom: 10px;
  }
  
  .profile-field label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .profile-field input {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .profile-actions {
    display: flex;
    justify-content: space-between;
  }
  
  .edit-button, .logout-button, .save-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .edit-button {
    background-color: #007bff;
    color: white;
  }
  
  .logout-button {
    background-color: #dc3545;
    color: white;
  }
  
  .save-button {
    background-color: #28a745;
    color: white;
  }
  
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Tilt+Neon&display=swap');

.main {
    background: linear-gradient(to bottom right, #000000 40%, #550000 75%, #aa0000 100%);
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; /* Centre les conteneurs horizontalement */
    justify-content: center; /* Centre les conteneurs verticalement */
}

.LogoContainer {
    display: flex;
    flex-direction: column; /* Place le logo et le texte en colonne */
    justify-content: center;
    align-items: center;
    flex: 0 1 auto; /* Réduit l'espace alloué au logo */
    padding-bottom: 0; /* Supprime l'espace en bas du logo */
    margin-bottom: 0; /* Supprime la marge en bas */
}

.AuthContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center; /* Centre le conteneur Auth horizontalement */
    flex: 0 1 auto; /* Réduit l'espace alloué à AuthContainer */
    padding-top: 0; /* Supprime l'espace en haut du AuthContainer */
    margin-top: 0; /* Supprime la marge en haut */
    width: 100%; /* Assure que le conteneur utilise toute la largeur disponible */
    max-width: 400px; /* Limite la largeur pour éviter qu'il soit trop large */
}

.neonText {
    font-family: "Tilt Neon", sans-serif;
    font-size: 3.5em;
    color: #fff;
    text-align: center;
    margin-top: 0;
    margin-bottom: 10px; /* Supprime l'espace en bas du texte */
}

.descriptionText {
    font-family: "DM Sans", sans-serif; /* Choix de la police */
    font-size: 1.2em; /* Taille de la police */
    color: #ffffff; /* Couleur du texte */
    text-align: center; /* Centrer le texte */
    margin-top: 10px; /* Espace au-dessus du paragraphe */
    margin-bottom: 20px; /* Espace en-dessous du paragraphe */
    line-height: 1.5; /* Espacement entre les lignes */
    max-width: 70%; /* Limite la largeur pour qu'il soit plus lisible */
}

.logo_1000 {
    max-width: 60%;
    height: auto;
    margin-bottom: 0; /* Supprime l'espace en bas du logo */
}

/* Styles pour les écrans de téléphone (jusqu'à 767px) */
@media screen and (max-width: 767px) {
    .main {
        flex-direction: column;
    }

    .neonText {
        font-size: 2em;
    }

    .descriptionText {
        display: none;
    }

    .logo_1000 {
        display: block;
        max-width: 36%;
        height: auto;
        margin-bottom: 0;
    }
}

/* Styles pour les écrans de tablette (768px à 1023px) */
@media screen and (min-width: 768px) and (max-width: 1023px) {
    .main {
        flex-direction: row;
    }

    .logo_1000 {
        display: block;
        max-width: 36%;
        min-width: 30%;
        height: auto;
        margin-bottom: 0;
    }

    .neonText {
        font-size: 2.5em;
    }

    .Main > div {
        flex: 1;
    }

    .AuthContainer {
        flex-grow: 0; /* Empêche le conteneur Auth de prendre trop de place */
        align-self: center; /* Centre le conteneur Auth horizontalement */
        padding-top: 0; /* Supprime l'espace au-dessus du AuthContainer */
        margin-top: 0; /* Supprime la marge en haut */
        width: 100%; /* Assure que le conteneur utilise toute la largeur disponible */
        max-width: 400px; /* Limite la largeur pour éviter qu'il soit trop large */
        margin-right: 10%;
    }

    .LogoContainer {
        flex-grow: 0;
        padding-bottom: 0; /* Supprime l'espace sous le LogoContainer */
    }
}

/* Styles pour les écrans d'ordinateur de bureau (1024px et plus) */
@media screen and (min-width: 1024px) {
    .main {
        flex-direction: row;
    }

    .logo_1000 {
        display: block;
        max-width: 30%;
        height: auto;
        margin-bottom: 0;
    }

    .Main > div {
        flex: 1;
    }

    .AuthContainer {
        flex-grow: 0; /* Empêche le conteneur Auth de prendre trop de place */
        align-self: center; /* Centre le conteneur Auth horizontalement */
        padding-top: 0; /* Supprime l'espace au-dessus du AuthContainer */
        margin-top: 0; /* Supprime la marge en haut */
        width: 100%; /* Assure que le conteneur utilise toute la largeur disponible */
        max-width: 400px; /* Limite la largeur pour éviter qu'il soit trop large */
        margin-right: 10%;
    }

    .LogoContainer {
        flex-grow: 0;
        padding-bottom: 0; /* Supprime l'espace sous le LogoContainer */
    }
}

/* Assure que l'élément root et body prennent toute la hauteur */
html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}

/* Assure que .App prend toute la hauteur de l'écran */
.App {
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Tilt+Neon&display=swap');

/* Pour Chrome, Safari, et d'autres navigateurs basés sur WebKit */
input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 50px #000 inset !important;
    -webkit-text-fill-color: white !important;
    border: solid black 5px;
}

/* Pour Mozilla Firefox */
input:-moz-autofill {
    box-shadow: 0 0 0 30px #000 inset !important;
    -moz-text-fill-color: white !important;
    border: solid black 5px;
}

/* Pour Microsoft Edge */
input:-ms-autofill {
    box-shadow: 0 0 0 30px #000 inset !important;
    -ms-text-fill-color: white !important;
    border: solid black 5px;
}


/* Styles généraux pour tous les écrans */
.input-container {
    position: relative;
    margin-bottom: 20px;
}

.input-container label{
    font-size: 16px; /* Taille du texte */
    font-family: "DM Sans", sans-serif; /* Police utilisée */
    color: white;
}

.input-container label {
    font-size: 16px; /* Taille du texte */
    font-family: "DM Sans", sans-serif; /* Police utilisée */
    color: white;
    width: 100%; /* S'assurer que le label prend toute la largeur disponible */
    text-align: left; /* Aligner le texte à droite */
    display: block; /* Assure que le label est traité comme un bloc pour occuper toute la largeur */
    margin-bottom: 5px; /* Espacement entre le label et l'input */
}

.custom-input {
    width: 100%;
    padding: 10px;
    padding-right: 40px; /* Place pour l'icône */
    background-color: #000; /* Fond noir */
    color: white; /* Texte blanc */
    border: 0px solid #555; /* Bordure grise */
    border-radius: 5px; /* Coins arrondis */
    font-size: 16px; /* Taille du texte */
    font-family: "DM Sans", sans-serif; /* Police utilisée */
}

.custom-input::placeholder {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: normal;
    color: white;
}

label {
    display: block;
    margin-bottom: 5px;
    color: #999;
    font-size: 14px;
}

.eye-icon {
    position: absolute;
    right: 20px;
    top: 45%;
    transform: translateY(-50%);
    cursor: pointer;
    color: white;
    font-size: 1em; /* Ajustez la taille de l'icône si nécessaire */
}

.eye-icon-inscription {
    position: absolute;
    right: 20px;
    top: 55%;
    transform: translateY(-50%);
    cursor: pointer;
    color: white;
    font-size: 1em; /* Ajustez la taille de l'icône si nécessaire */
}

/* Styles pour les écrans de téléphone (jusqu'à 767px) */
@media screen and (max-width: 767px) {
    .mainAuth {
        padding: 20px;
        border-radius: 20px;
        max-width: 80%;
        margin: 0 auto;
    }
    
    .mainAuth input {
        width: 100%;
        height: 3rem;
        padding: 10px;
        margin-bottom: 15px;
        border-radius: 5px;
        box-sizing: border-box;
    }
    
    .mainAuth button {
        width: 60%;
        padding: 10px;
        margin-top: 10px;
        border: none;
        border-radius: 5px;
        background-color: white;
        color: black;
        cursor: pointer;
        font-family: "Poppins", sans-serif; /* Applique la police Poppins */
        font-weight: 700; /* Définit le poids de la police à bold (700) */
    }
    
    .mainAuth button:hover {
        background-color: #e1e1e1;
    }
    
    h1 {
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        color: white;
        margin-bottom: 10px;
    }

    .connectionDiv p, span {
        font-family: "DM Sans", sans-serif;
        font-style: normal;
        color: white;
        margin-top: 0;
    }
}

/* Styles pour les écrans de tablette (768px à 1023px) */
@media screen and (min-width: 768px) and (max-width: 1023px) {
    .mainAuth {
        padding: 20px;
        border-radius: 20px;
        max-width: 400px;
        margin: 0 auto;
    }
    
    .mainAuth input {
        width: 100%;
        height: 3rem;
        padding: 10px;
        margin-bottom: 15px;
        border-radius: 5px;
        box-sizing: border-box;
    }
    
    .mainAuth button {
        width: 60%;
        padding: 10px;
        margin-top: 10px;
        border: none;
        border-radius: 5px;
        background-color: white;
        color: black;
        cursor: pointer;
        font-family: "Poppins", sans-serif; /* Applique la police Poppins */
        font-weight: 700; /* Définit le poids de la police à bold (700) */
    }
    
    .mainAuth button:hover {
        background-color: #e1e1e1;
    }
    
    h1 {
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        color: white;
        margin-bottom: 10px;
    }

    .connectionDiv p, span {
        font-family: "DM Sans", sans-serif;
        font-style: normal;
        color: white;
        margin-top: 0;
    }
}

/* Styles pour les écrans d'ordinateur de bureau (1024px et plus) */
@media screen and (min-width: 1024px) {
    .mainAuth {
        padding: 20px;
        border-radius: 20px;
        max-width: 400px;
        margin: 0 auto;
    }
    
    .mainAuth input {
        width: 100%;
        height: 3rem;
        padding: 10px;
        margin-bottom: 15px;
        border-radius: 5px;
        box-sizing: border-box;
    }
    
    .mainAuth button {
        width: 60%;
        padding: 10px;
        margin-top: 10px;
        border: none;
        border-radius: 5px;
        background-color: white;
        color: black;
        cursor: pointer;
        font-family: "Poppins", sans-serif; /* Applique la police Poppins */
        font-weight: 700; /* Définit le poids de la police à bold (700) */
    }
    
    .mainAuth button:hover {
        background-color: #e1e1e1;
    }
    
    h1 {
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        color: white;
        margin-bottom: 10px;
    }

    .connectionDiv p, span {
        font-family: "DM Sans", sans-serif;
        font-style: normal;
        color: white;
        margin-top: 0;
    }
}
